<template>
  <div>
    <UpdateServerAppForm v-if="ready" :serverApp="serverApp"/>
    <div v-else>
      <b-row>
        <b-col/>
        <b-col cols="1">
          <AppPulsingIcon/>
        </b-col>
        <b-col/>
      </b-row>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import UpdateServerAppForm from "@/components/serverApps/UpdateServerAppForm";
import AppPulsingIcon from "@/components/AppPulsingIcon";

export default {
  components: {
    UpdateServerAppForm,
    AppPulsingIcon,
  },
  props: {
    uid: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      ready: false,
    };
  },
  computed: {
    ...mapState({
      serverApp: (state) => state.serverApps.serverApp,
    }),
  },
  async created() {
    try {
      await this.$store.dispatch("serverApps/fetchServerApp", this.uid);
    } catch (e) {
      await this.$swal.fire({
        icon: "error",
        title:
            "Une erreur est survenue lors de la récupération de l'application server",
        text: e.message,
      });
      await this.$router.push({name: 'serverApps'})
      return;
    }
    this.ready = true;
  },
};
</script>

