<template>
  <b-button @click="emitReload" variant="outline-primary">
    <font-awesome-icon icon="redo-alt"/>
  </b-button>
</template>

<script>
import IrreversibleActionSwal from "@/IrreversibleActionSwal";

export default {
  props: {
    prevent: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    async emitReload() {
      let prevented = false;
      if (this.prevent) {
        prevented = true;
        const result = await IrreversibleActionSwal.fire();
        if (result.value === true) {
          prevented = false;
        }
      }

      if (!prevented) {
        this.$emit("reload");
      }
    },
  },
};
</script>