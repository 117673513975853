<template>
  <b-button @click="copyToClipBoard" variant="outline-primary">
    <font-awesome-icon icon="copy"/>
  </b-button>
</template>

<script>
export default {
  props: {
    toCopy: {
      type: String,
      required: true,
    },
  },
  methods: {
    async copyToClipBoard() {
      let result;
      try {
        result = await navigator.permissions.query({
          name: "clipboard-write",
        });
      } catch (e) {
        await this.$swal.fire({
          icon: "warning",
          title:
              "Le navigateur n'a pas les droits nécessaire pour pouvoir écrire dans le presse papier",
        });
        return;
      }

      if (result.state === "granted" || result.state === "prompt") {
        try {
          await navigator.clipboard.writeText(this.toCopy);
          this.$toast.success("Copié");
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error("Erreur lors de la copie dans le presse papier");
        }
      }
    },
  },
};
</script>